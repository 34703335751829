<template>
  <div>
    <b-tabs v-model="tabIndex">
      <b-tab title="월별">
        <div class="clearfix mb-2">
          <strong class="pull-left">KR 대시보드(월별)</strong>

          <div class="pull-right">
            <small>{{ dt ? '기준: ' + dt : '' }}</small>
            <b-button class="ml-2" variant="success" size="sm" @click="list" :disabled="busy.list">Refresh
              <b-spinner class="ml-1" small v-if="busy.list"></b-spinner>
            </b-button>
          </div>
        </div>
        <hot-table ref="hotTable" :settings="hotSettings"></hot-table>
      </b-tab>
      <b-tab title="일별">
        <div class="clearfix mb-2">
          <strong class="pull-left">KR 대시보드(일별)</strong>

          <div class="pull-right">
            <small>{{ dt_daily ? '기준: ' + dt_daily : '' }}</small>
            <b-button class="ml-2" variant="success" size="sm" @click="list" :disabled="busy.list">Refresh
              <b-spinner class="ml-1" small v-if="busy.list"></b-spinner>
            </b-button>
          </div>
        </div>
        <hot-table ref="hotTableDaily" :settings="hotSettingsDaily"></hot-table>
      </b-tab>
    </b-tabs>

    <b-card class="mt-4">
      <div slot="header">
        <strong class="pull-left">[Tobe] KR 대시보드 시트 링크</strong>
      </div>
      <a href="https://docs.google.com/spreadsheets/d/1InVA2B10ieYlgRn0jyqzkBB7HKo2rXrjkI6vfnI686Q" target="_blank"
         class="btn btn-primary mr-2">KR 대시보드_2024</a>
    </b-card>

    <b-card class="mt-4">
      <div slot="header">
        <strong class="pull-left">[Asis] KR 대시보드 시트 링크</strong>
      </div>
      <a href="https://drive.google.com/drive/folders/1dqpJMbDehDja7dejpVKx3wOaIyUeGXhh" target="_blank" class="btn btn-primary mr-2">KR 대시보드 프로젝트 폴더</a>
      <a href="https://docs.google.com/spreadsheets/d/1Wyq-xt1RnGbGmQq0Ql_-f3VBcqCLfu95ZveGOdok4_I" target="_blank" class="btn btn-success mr-2">KR
        대시보드_MD</a>
      <a href="https://docs.google.com/spreadsheets/d/1y7_ntYBB5ohfW_1mD6qndQrbe2N6AA7CdS2yljIr2vY" target="_blank"
         class="btn btn-success mr-2">KR 대시보드_2020_auto</a>
      <a href="https://docs.google.com/spreadsheets/d/1sR9c7EFrxgDB1ycGo4XD_4Z1dqau-kqjBbqJS0vgTRQ" target="_blank" class="btn btn-success mr-2">KR대시보드_goods_auto</a>
      <a href="https://docs.google.com/spreadsheets/d/1qsnGvbHYasNr5Hl8Q7tEoG29RuFlJFC-LqQYaqulpAA" target="_blank"
         class="btn btn-success mr-2">KR대시보드_partner</a>
      <a href="https://docs.google.com/spreadsheets/d/1vOSNqNujY3U-EN2m6BmhZRtiSZN_OJscXFE-M-RsiOw" target="_blank" class="btn btn-success mr-2">KR대시보드_제품팀</a>
      <a href="https://docs.google.com/spreadsheets/d/1chUMu4ZYK0HpxC_SMgDRRlBFruguSMKk/edit?usp=drive_web&ouid=101229612034213669079&dls=true" target="_blank"
         class="btn btn-secondary mr-2">KR대시보드_원안.xlsx</a>
      <a href="https://docs.google.com/spreadsheets/d/1gTYyQxexVy3wp0tcZ4PSaQZv3B_oC2yuJ7IC4cj8Es4" target="_blank" class="btn btn-secondary mr-2">2020_KR대시보드_NEW</a>
    </b-card>

    <b-card>
      <div slot="header">
        <strong class="pull-left">MD 대시보드 생성</strong>
      </div>
      <b-row class="mb-2">
        <b-col cols="2">
          <small>주요지표</small><br/>
          <b-form-select class="col-form-label" v-model="md.field" :options="FIELDS.map(e=>({text:e, value:e}))"></b-form-select>
        </b-col>
        <b-col cols="2">
          <small>구분방식</small><br/>
          <b-form-select class="col-form-label" v-model="md.row" :options="ROWS.map(e=>({text:e, value:e}))"></b-form-select>
        </b-col>
        <b-col>
          <small>기간설정</small><br/>
          <b-form inline>
            <b-form-select :options="COLS.map(e=>({text:e, value:e}))" v-model="md.col"></b-form-select>
            <date-input v-model="md.st"></date-input>
            &nbsp;∽&nbsp;
            <date-input v-model="md.ed"></date-input>
          </b-form>
        </b-col>
        <b-col cols="1">
          <small>미사용 SHOP</small><br/>
          <b-form-select class="col-form-label" v-model="md.unused" :options="[{text:'미포함', value:'N'},{text:'포함', value:'Y'}]"></b-form-select>
        </b-col>
        <b-col cols="3">
          <small>SheetID</small><br/>
          <b-input-group>
            <b-form-input v-model="md.spreadsheetId"></b-form-input>
            <b-input-group-append>
              <b-button variant="primary" @click="$utils.open(`https://docs.google.com/spreadsheets/d/${md.spreadsheetId || DEFAULT_ID}/`)"><i
                class="fa fa-external-link"></i></b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
      <b-button variant="success" @click="makeSheet" :disabled="busy.make">
        생성하기
        <b-spinner class="ml-1" small v-if="busy.make"></b-spinner>
      </b-button>
    </b-card>

    <b-alert show variant="warning">상품수 계열은 파트너별, 일별 데이터가 아닐 경우 문제가 있을 수 있습니다.</b-alert>
  </div>
</template>
<style>
@import '~handsontable/dist/handsontable.full.css';
</style>
<script>
import dateInput from '@/views/modules/DateInput.vue'
import {HotTable} from '@handsontable/vue';
import {commaRenderer, percentRenderer} from '@/shared/ht_helper';

export default {
  name: 'KRdashboard',
  title: 'KR 대시보드',
  components: {dateInput, HotTable},
  data() {
    return {
      FIELDS: '거래액,실거래액,취소매출,실매출비율,공헌이익,공헌이익률,거래수,객단가,기초상품수,등록상품수,품절상품수,기말상품수,실주문수,품절수,품절률,주문취소,반품,평균배송일'.split(','),
      ROWS: '소싱채널,파트너,판매채널,팀,담당자'.split(','),
      COLS: '일,주,월'.split(','),
      DEFAULT_ID: '1Wyq-xt1RnGbGmQq0Ql_-f3VBcqCLfu95ZveGOdok4_I',
      busy: {list: false, make: false},
      dt: '',
      dt_daily: '',
      items: {list: [], daily: []},
      tabIndex: 0,
      hotSettings: {
        colHeaders: this.$C.KRD_COLUMNS.map(e => e.name),
        columns: [
          ...this.$C.KRD_COLUMNS.map(e => ({
            data: e.code, readOnly: true, className: ({right: 'htRight', center: 'htCenter'}[e.align] || ''),
            ...(e.type === "percent" ? {renderer: percentRenderer} :
              e.type === 'number' ? {renderer: commaRenderer} :
                {className: 'htCenter text-dark'})
          })),
        ],
        className: "htMiddle",
        autoWrapCol: false,
        autoWrapRow: false,
        manualColumnResize: true,
        colWidths: this.$C.KRD_COLUMNS.map(e => e.width),
        height: 400,
        licenseKey: 'non-commercial-and-evaluation',
      },
      hotSettingsDaily: {
        colHeaders: this.$C.KRD_COLUMNS.map(e => e.name),
        columns: [
          ...this.$C.KRD_COLUMNS.map(e => ({
            data: e.code, readOnly: true, className: ({right: 'htRight', center: 'htCenter'}[e.align] || ''),
            ...(e.type === "percent" ? {renderer: percentRenderer} :
              e.type === 'number' ? {renderer: commaRenderer} :
                {className: 'htCenter text-dark'})
          })),
        ],
        className: "htMiddle",
        autoWrapCol: false,
        autoWrapRow: false,
        manualColumnResize: true,
        colWidths: this.$C.KRD_COLUMNS.map(e => e.width),
        height: 400,
        licenseKey: 'non-commercial-and-evaluation',
      },
      md: {
        field: '거래액', row: '파트너', col: '일', unused: 'N', spreadsheetId: '',
        st: this.$moment().startOf('month').format('YYYY-MM-DD'),
        ed: this.$moment().endOf('month').format('YYYY-MM-DD'),
      },
    }
  },
  async created() {
    this.list().then();
  },
  watch: {
    tabIndex(v) {
      if (v === 1) {
        setTimeout(() => this.$refs.hotTableDaily.hotInstance.render(), 0);
      }
    },
  },
  methods: {
    async list() {
      this.busy.list = true;
      let j = await this.$api.getJson('/data/kr');
      let predict = await this.$api.getJson('/data/kr_predict');
      let daily = await this.$api.getJson('/data/kr/daily');
      this.busy.list = false;
      this.items.list = j.list.sort((a, b) => b.date.localeCompare(a.date));
      this.items.daily = daily.list.sort((a, b) => b.date.localeCompare(a.date));
      this.items.list.unshift.apply(this.items.list, predict.list);
      this.dt = j._dt;
      this.dt_daily = daily._dt;
      this.$refs.hotTable.hotInstance.loadData(this.items.list);
      this.$refs.hotTableDaily.hotInstance.loadData(this.items.daily);
    },
    async makeSheet() {
      this.busy.make = true;
      let j = await this.$api.postJson('/data/sheet', {...this.md, st: this.md.st.replace(/-/g, ''), ed: this.md.ed.replace(/-/g, '')});
      this.busy.make = false;
      if (j) {
        this.$alertTop('시트가 생성되었습니다', {timeout: 2000});
      }
    }
  }
}
</script>
